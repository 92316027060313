import React, { useState } from "react";

const App = () => {
  return (
    <>
      <Header />
      <About/>
    </>
  );
};

export default App;

const Header = () => {
  const [toggle, setToggle] = useState(false)
  return (
    <nav className="bg-orange-400 py-3 w-full">
      <div className="flex flex-wrap lg:justify-between items-center max-w-screen-xl px-4 mx-auto">
        <a>
          <span>Okoa Maisha Na Health Info</span>
        </a>
        <div className="flex lg:order-2 items-center">
          <button className="bg-red-800 p-3 mx-3 lg:hidden text-white" onClick={()=> setToggle(!toggle)}>
              {!toggle ? "Open": "Close"}
          </button>
        </div>
        <div className={`${toggle ? "block": "hidden"} items-center justify-between w-full lg:flex lg:w-auto lg:order-1`}>
          <ul className="flex lg:flex-row flex-col lg:space-x-4 text-white cursor-poniter upppercase">
            <li className="hover:bg-red-400">Home</li>
            <li className="hover:bg-red-400">About</li>
            <li className="hover:bg-red-400">Services</li>
            <li className="hover:bg-red-400">Email Address</li>
            <li className="hover:bg-red-400">Contact number</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const About = ()=> {
  return (
    <>
    <div className="w-full">
      <img src="image/naih.jpg" className="w-full"/>
    </div>
    </>
  )
}

